<template>
    <div class="credit-goods-create" style="height: 100%">
        <div class="contract-create-title">
            <a-row>
                <a-col :span="3">
                    添加积分商品
                </a-col>
                <a-col :span="18">
                </a-col>
                <a-col :span="3">
                    <a-button type="primary">提交</a-button>
                    <a-button>返回</a-button>
                </a-col>
            </a-row>
        </div>
        <div  class="H-gap-gray-10"></div>
        <div class="student-profile-row content-item-block">
            <a-form-model layout="inline" v-model="createGoodsForm" class="">
                <div class="goods-basic-info padding-left-40 padding-vertical-40">
                    <div class="vertical-blue-line title-row">基本信息</div>
                    <div class="padding-left-10">
                        <a-row class="padding-vertical-10 ">
                            <a-col :span="8">
                                <a-form-model-item label="商品名称" required :labelCol=" {span: 6}" :wrapper-col="{ span: 24 }">
                                    <a-input placeholder="请输入商品名称"/>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-model-item label="覆盖中心" style="width: 100%;" required
                                                   :labelCol=" {span: 3,offset:0 ,textAlign:'left'}"
                                                   :wrapper-col="{ span: 24 }">
                                    <a-tree-select style="width: 300px"
                                                   v-model="createGoodsForm.centerId"
                                                   show-search
                                                   :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                                   placeholder="请选择分单人中心"
                                                   allow-clear
                                                   tree-default-expand-all
                                    >
                                        <a-tree-select-node v-for="(topItem,topIndex) in userCenters" :key="topIndex"
                                                            :value="topItem.centerID" :title="topItem.centerName"
                                                            :disabled="topItem.disabled">
                                            <a-tree-select-node v-for="(secItem,secIndex) in topItem.children" :key="secIndex"
                                                                :value="secItem.centerID" :title="secItem.centerName"
                                                                :disabled="secItem.disabled">
                                                <a-tree-select-node v-for="(thirdItem,thirdIndex) in secItem.children"
                                                                    :key="thirdIndex" :value="thirdItem.centerID"
                                                                    :title="thirdItem.centerName"
                                                                    :disabled="thirdItem.disabled">

                                                </a-tree-select-node>
                                            </a-tree-select-node>
                                        </a-tree-select-node>
                                    </a-tree-select>
                                </a-form-model-item>
                            </a-col>
                        </a-row>

                        <a-row class="padding-vertical-10">
                            <a-col :span="8">
                                <a-form-model-item label="积分价格" :labelCol=" {span: 3}" :wrapper-col="{ span: 24 }" required>
                                    <a-row>
                                        <a-col :span="4">
                                            <a-input default-value="1"/>
                                        </a-col>
                                        <a-col :span="3">&nbsp;&nbsp;积分&nbsp;&nbsp;&nbsp;&nbsp;+</a-col>
                                        <a-col :span="5">
                                            <a-input default-value="0"/>
                                        </a-col>
                                        <a-col :span="4">&nbsp;&nbsp;元</a-col>
                                    </a-row>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-model-item label="价值" required :labelCol=" {span: 3}" :wrapper-col="{ span: 24 }">
                                    <a-input placeholder="请输入商品市价" style="max-width: 350px;min-width: 250px;"/>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-model-item label="可兑换数量" required :labelCol=" {span: 6}"
                                                   :wrapper-col="{ span: 24 }">
                                    <a-input placeholder="请输入可兑换数量" style="max-width: 350px;min-width: 250px;"/>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <br>
                        <a-row class="padding-vertical-10">
                            <a-form-model-item label="关联产品" required :labelCol=" {span: 3}" :wrapper-col="{ span: 24 }"
                                               style="width: 500px">
                                <a-select
                                    mode="tags"
                                    placeholder="请选择要关联的产品"
                                    :default-value="[]"
                                    style="width: 280px"
                                    @change="bindGoodsChange"
                                >
                                    <a-select-option :value="goods.Id" v-for="(goods,index) in goodsList" :key="index">
                                        {{ goods.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-row>
                    </div>
                </div>

                <div  class="H-gap-gray-10" ></div>

                <div class="padding-left-40 padding-vertical-40">
                    <div class="vertical-blue-line title-row">商品详情</div>
                    <div class="padding-left-10">
                        <a-form-model-item label="头图" required :labelCol=" {span: 6}"
                                           :wrapper-col="{ span: 24 }">
                            <a-upload
                                name="avatar"
                                list-type="picture-card"
                                class="avatar-uploader"
                                :show-upload-list="false"
                                :action="uploadUrl"
                                :before-upload="beforeThumbUpload"
                                @change="handleThumbChange"
                            >
                                <img v-if="createGoodsForm.thumb" :src="createGoodsForm.thumb" alt="avatar"/>
                                <div v-else>
                                    <a-icon :type="loading ? 'loading' : 'plus'"/>
                                    <div class="ant-upload-text">
                                        添加
                                    </div>
                                </div>
                            </a-upload>
                        </a-form-model-item>
                        <br>
                        <a-form-model-item label="详情图" required :labelCol=" {span: 24}"
                                           :wrapper-col="{ span: 24 }">
                            <a-upload
                                name="avatar"
                                list-type="picture-card"
                                class="avatar-uploader"
                                :show-upload-list="false"
                                :action="uploadUrl"
                                :before-upload="beforeThumbUpload"
                                @change="handleThumbChange"
                            >
                                <img v-if="createGoodsForm.thumb" :src="createGoodsForm.thumb" alt="avatar"/>
                                <div v-else>
                                    <a-icon :type="loading ? 'loading' : 'plus'"/>
                                    <div class="ant-upload-text">
                                        添加
                                    </div>
                                </div>
                            </a-upload>
                        </a-form-model-item>
                    </div>
                </div>

                <div style="height: 10px;background-color: lightgrey;offset-start:20px "></div>

                <div  class="padding-left-40 padding-vertical-40">
                    <div class="vertical-blue-line title-row">其他</div>
                    <div class="padding-left-10">
                        <a-form-model-item label="备注" required :labelCol=" {span:1}"  :wrapper-col="{ span: 24 }" style="width: 95%">
                            <a-textarea placeholder="请输入备注" :rows="4" />
                        </a-form-model-item>
                    </div>
                </div>

            </a-form-model>

        </div>
    </div>
</template>
<script>
export default {
    name: 'CreditGoodsCreate',
    data() {
        return {
            loading:false,

            uploadUrl:'',

            customPlainForm: {
                feeStandard: [1, 11, 111],
            },
            createGoodsForm: {},
            userCenters: [
                {
                    centerID: 'jiangsu',
                    centerName: '华南区',
                    disabled: true,
                    children: [
                        {
                            centerID: 'nanjing',
                            centerName: '广东',
                            disabled: true,
                            children: [
                                {
                                    centerID: 'zhonghuamen',
                                    centerName: '深圳七田真',
                                    disabled: false,
                                },
                            ],
                        }
                    ]
                },
            ],
            goodsList: [
                {name: "SSQ00083 - 七田真好好鸭书包", Id: "a5f5e54rg1"},
                {name: "SSQ22083 - 七田真好好鸭书包", Id: "b5f5e44rg3"},
                {name: "SSQ03383 - 七田真好好鸭书包", Id: "35f5e5ddg1"},
            ]
        }
    },
    created() {
    },
    mounted() {

    },
    methods: {
        plainTypeChange(e) {
            console.log(e.target.value)
        },
        changeGuardianSubmit() {
            this.commissionInfoVisible = true;
        },
        bindGoodsChange() {

        },
        beforeThumbUpload(){

        },
        handleThumbChange(){

        }
    }
}
</script>
<style scoped lang="less">
.credit-goods-create {
    box-sizing: border-box;
    margin-bottom: 120px;
    background-color: #fff;

    .contract-create-title {
        background-color: white;
        padding-left: 40px;
        padding-top: 30px;
        padding-bottom: 30px;
        font-size: 16px;
        font-weight: bold;
    }

    .content-item-block {
        background-color: #fff;
        padding-top: 40px;
        padding-bottom: 40px;
        margin-bottom: 10px;
    }


    .student-profile-row {
        background-color: #fff;
        padding-top: 30px;

    }



}
.vertical-blue-line {
    border-left: #108ee9 solid 4px;
    padding-left: 10px;
}
.padding-left-40{
    padding-left: 40px;
}
.padding-left-10{
    padding-left: 10px;
}

.padding-vertical-10{
    padding-top:  10px;
    padding-bottom:  10px;
}

.padding-vertical-40{
    padding-top:  40px;
    padding-bottom:  40px;
}

.H-gap-gray-10{
    height: 10px;
    background-color: lightgrey;
}
</style>
